/* sonar-ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-grey-background text-text-primary font-body antialiased overflow-x-hidden;
}

/* sonar-ignore */
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

/* sonar-ignore */
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .shimmer {
        background: linear-gradient(120deg, #f3f4f6 20%, #e5e7eb 50%, #f3f4f6 80%);
        background-size: 1000px 100%;
        background-repeat: repeat-x;
        animation: shimmer 5s linear infinite;
    }
    .shimmer::before {
        transform: skewX(45deg);
    }

    .animate-spin {
        animation: spin 5s linear infinite;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px;
    }
    100% {
        background-position: 1000px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
